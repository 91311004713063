<template>
  <div>
    <helloWorld />
    <div class="content-1180">
      <p class="sort">热门排名</p>
      <div class="rank-select-wrap" style="text-align:center">
        <el-select size="mini" v-model="value" placeholder="日期">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" v-model="value" placeholder="底价" style="margin-left:10px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select size="mini" v-model="value" placeholder="项目" style="margin-left:10px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <div class="rank-item">
          <div>收藏品</div>
          <div>总计</div>
          <div>24小时内</div>
          <div>7天内</div>
          <div class="phone-hide">底价</div>
          <div class="phone-hide">拥有者</div>
          <div class="phone-hide">项目</div>
        </div>
        <div class="rank-body" v-for="(ling, index) in rankList" :key="index">
          <div class="rank-first" style="text-align:left;justify-content:left;padding-left:10px">
            <p>{{index+Number(1)}}</p>
            <img :src="ling.albunImg" alt="">
            <p>{{ling.albumTile}}</p>
          </div>
          <div class="rank-num">{{ling.total}}</div>
          <div class="rank-range">{{ling.hours}}</div>
          <div class="rank-range">{{ling.days}}</div>
          <div class="rank-num phone-hide">{{ling.floorPrice}}</div>
          <div class="rank-num phone-hide">{{ling.onwer}}</div>
          <div class="rank-num phone-hide">{{ling.project}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      rankList: [
        {
          albumTile: '加密朋克',
          albunImg: require(`@/assets/rank1.jpg`),
          hours: '-30.85%',
          days: '+144.76%',
          floorPrice: '--',
          onwer: '亚联融汇',
          project: '200',
          total: '14,833',
          desc: '“加密朋克”系列由 10000个 24x24 像素的艺术图像通过算法组成。大多数图像都是看起来很笨拙的男孩和女孩，但也有一些比较罕见的类型：猿、僵尸，甚至是奇怪的外星人。在项目官网每个朋克都有自己的个人资料页面，显示他们的属性以及他们的所有权/出售状态。加密朋克灵感来源于朋克文化，用于展现早期的区块链运动鲜明的反建制精神。每个图像都有假定的个性和随机生成的特征。'
        },
        {
          albumTile: '沙特人',
          albunImg: require(`@/assets/rank2.jpg`),
          hours: '-54.52%',
          days: '+756.21%',
          floorPrice: '0.6',
          onwer: '亚联融汇',
          project: '5.6k',
          total: '7,171,99',
          desc: 'Saudis项目方想建一个沙特王国，于是造就5,555 名沙特人的永久家庭 永远在以太坊区块链（链上）上进行 MAX BIDDING。其也是一个专属俱乐部——MAX BIDDING 登顶。每个沙特人都是独一无二的，并且由 80 多种可能的特征以编程方式生成。'
        },
        {
          albumTile: '策展的艺术块',
          albunImg: require(`@/assets/rank3.jpg`),
          hours: '-35.62%',
          days: '+49.10%',
          floorPrice: '--',
          onwer: '亚联融汇',
          project: '58.1k',
          total: '4,977,27',
          desc: ''
        },
        {
          albumTile: '为他者做他事',
          albunImg: require(`@/assets/rank4.png`),
          hours: '+70.83%',
          days: '+4.18%',
          floorPrice: '2.98',
          onwer: '亚联融汇',
          project: '100k',
          total: '4,549,19',
          desc: 'Otherside 是即将到来的游戏化元节。 它将角色扮演游戏的元素与支持 web3 的虚拟世界融合在一起。 它的目的是让你拥有一个多人虚拟卫星，无论你的 NFT 走到哪里，都可以玩角色。'
        },
        {
          albumTile: '无聊猿',
          albunImg: require(`@/assets/rank5.png`),
          hours: '--',
          days: '+27.02%',
          floorPrice: '99.6',
          onwer: '亚联融汇',
          project: '100k',
          total: '4,291,45',
          desc: 'Bored Ape Yacht Club（无聊猿俱乐部，BAYC）是由一万个猿猴NFT组成的数字收藏品合集。包括了帽子，眼睛，神态，服装，背景等170个稀有度不同的属性。'
        },
        {
          albumTile: '突变猿',
          albunImg: require(`@/assets/rank6.jpg`),
          hours: '--',
          days: '-37.45%',
          floorPrice: '19.68',
          onwer: '亚联融汇',
          project: '19.4k',
          total: '6,545,63',
          desc: 'MAYC是Yuga Labs迄今最创造力的NFT项目，这批MAYC是BAYC的变异种，扭曲的体态、夸张的神情、大胆的配饰让玩家大呼过瘾！'
        }
      ], //排名列表
      options: [],
        value: ''
    }
  },
  methods: {
    toType(row) {
      this.$router.push({path: './author', query: {total: row.total, project: row.project, onwer: row.onwer, floorPrice:row.floorPrice, albumTile: row.albumTile, albunImg: row.albunImg, desc: row.desc}});
    },
  }
}
</script>
<style scoped>
.content-1180 {
  max-width: 1180px;
  margin: auto;
}
.sort {
  font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(4, 17, 29);
    text-align: center;
    padding: 30px 0 20px 0;
}
.rank-item {
  display: flex;
  padding: 10px 0 15px 0;
  margin-top: 20px;
  border-bottom: 1px solid #eee;
}
.rank-item div {
  width: 16%;
  text-align: center;
  font-weight: 600;
    font-size: 16px;
    color: rgb(4, 17, 29);
}
.rank-body {
  display: flex;
  padding: 14px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}
.rank-first {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank-first img {
  width: 30px;
  margin-left: 10px;
  height: 30px;
  border-radius: 50%;
}
.rank-body div {
  width: 16%;
  text-align: center;
}
.rank-first p {
  font-size: 14px;
  margin-left: 4px;
}
.rank-num {
  font-size: 14px;
}
.rank-range {
  font-size: 14px;
  color: rgb(52, 199, 123);
}
.rank-body:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
  cursor: pointer;
}
</style>